'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function getOpt(a, i) {
  try {
    return Caml_option.some(Caml_array.caml_array_get(i, a));
  }
  catch (exn){
    if (exn === Caml_builtin_exceptions.not_found) {
      return ;
    } else {
      throw exn;
    }
  }
}

function isEmpty(a) {
  return a.length === 0;
}

function isNotEmpty(a) {
  return !isEmpty(a);
}

exports.getOpt = getOpt;
exports.isEmpty = isEmpty;
exports.isNotEmpty = isNotEmpty;
/* No side effect */
