'use strict';

var Home = require("./components/Home.bs.js");
var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");

((require("./tailwind.css")));

ReactDOMRe.renderToElementWithId(React.createElement(Home.make, { }), "root");

/*  Not a pure module */
